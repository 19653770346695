.s-aside {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: $spacing-unit;
    padding-top: $spacing-unit-small;
    border-top: solid 1px $color-highlight;
    font-size: $font-size-6;
    font-weight: 300;
  }

  @include mq(tablet) {
    font-size: $font-size-6;
  }

  @include mq(desktop) {
    font-size: $font-size-5;
  }
}
