.c-hero {
  position: relative;
  background-color: $color-highlight;
  padding-top: $spacing-unit-giga;
  padding-bottom: $spacing-unit-giga;
  padding-left: $spacing-unit-large;
  padding-right: $spacing-unit-large;
  text-align: center;
  color: $color-shade;
  background-size: cover;
  background-position: bottom;
  z-index: 1;

  &--profile {
    padding-top: $spacing-unit-huge;
    padding-bottom: $spacing-unit-huge;
  }


  @include mq(small) {
    padding-left: $spacing-unit-huge;
    padding-right: $spacing-unit-huge;
  }

  @include mq(desktop) {
    padding-left: $spacing-unit-giga;
    padding-right: $spacing-unit-giga;
  }



  &__overlay {
    background: rgba(darken($color-highlight, 20%), .7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__wrapper {
    padding-left: $spacing-unit-large;
    padding-right: $spacing-unit-large;
    margin: 0 auto;
    z-index: 10;

    @include mq(tablet) {
      max-width: 75%;
    }

    @include mq(desktop) {
      max-width: 50%;
    }
  }

  &__heading {
    font-family: $font-family-heading;
    font-size: $font-size-4;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;

    &:after {
      width: $spacing-unit-huge;
      height: .125rem;
      display: block;
      content: '';
      background: #fff;
      margin: 0 auto;
      margin-top: $spacing-unit;
    }
  }
}
