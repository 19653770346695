.c-brand {
  margin-bottom: 0;
  font-family: $font-family-heading;
  font-size: $font-size-5;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  color: $color-highlight;

  @include mq(small) {
    font-size: $font-size-3;
  }

  @include mq(tablet) {
    font-size: $font-size-2;
  }

  &--minor {
    font-size: $font-size-5;

    @include mq(small) {
      font-size: $font-size-4;
    }
  }
}
