/* ==========================================================================
   #HEADINGS
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6, {
  font-size: $font-size-4;
  font-family: $font-family-heading;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
}