.u-f1 {
  font-size: $font-size-1 !important;
}
.u-f2 {
  font-size: $font-size-2 !important;
}
.u-f3 {
  font-size: $font-size-3 !important;
}
.u-f4 {
  font-size: $font-size-4 !important;
}
.u-f5 {
  font-size: $font-size-5 !important;
}
.u-f6 {
  font-size: $font-size-6 !important;
}
.u-f-mega {
  font-size: $font-size-mega !important;
}
.u-f-giga {
  font-size: $font-size-giga !important;
}





.u-lh-solid {
  line-height: $line-height-solid !important;
}
.u-lh-default {
  line-height: $line-height-default !important;
}
.u-lh-title {
  line-height: $line-height-title !important;
}
.u-lh-copy {
  line-height: $line-height-copy !important;
}





.u-measure {
  max-width: $measure-width !important;
}
.u-measure-narrow {
  max-width: $measure-narrow-width !important;
}
.u-measure-wide {
  max-width: $measure-wide-width !important;
}




.u-tracked {
  letter-spacing: $font-tracked-width !important;
}




.u-font-heading {
  font-family: $font-family-heading !important;
}




.u-fw-bold {
  font-weight: $font-weight-bold !important;
}




.u-text-center {
  text-align: center !important;
}
.u-text-right {
  text-align: right !important;

  &\@tablet {
    @include mq(tablet) {
      text-align: right !important;
    }
  }
}

.u-text-left {
  text-align: left !important;
}
