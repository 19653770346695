.c-refer {
  opacity: .8;
  margin-top: $spacing-unit;
  font-family: $font-family-heading;
  font-weight: 300;
  font-size: $font-size-6;

  &__link {
    font-weight: bold;
    letter-spacing: .05em;
  }
}
