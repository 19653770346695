// INUIT
$inuit-global-font-size: 16px;


// SPACING

$spacing-unit: 1rem;
$spacing-unit: $spacing-unit;

$spacing-unit-factor-tiny: 0.25;
$spacing-unit-factor-small: 0.5;
$spacing-unit-factor-large: 2;
$spacing-unit-factor-huge: 4;
$spacing-unit-factor-mega: 5;
$spacing-unit-factor-giga: 6;

// Reassign so they are still calculated in core settings
$inuit-global-spacing-unit-factor-tiny: $spacing-unit-factor-tiny;
$inuit-global-spacing-unit-factor-small: $spacing-unit-factor-small;
$inuit-global-spacing-unit-factor-large: $spacing-unit-factor-large;
$inuit-global-spacing-unit-factor-huge: $spacing-unit-factor-huge;

// Alias inuitcss spacing variables
$spacing-unit-tiny:  $spacing-unit * $spacing-unit-factor-tiny;
$spacing-unit-small: $spacing-unit * $spacing-unit-factor-small;
$spacing-unit-large: $spacing-unit * $spacing-unit-factor-large;
$spacing-unit-huge:  $spacing-unit * $spacing-unit-factor-huge;
$spacing-unit-mega:  $spacing-unit * $spacing-unit-factor-mega;
$spacing-unit-giga: $spacing-unit * $spacing-unit-factor-giga;

$inuit-spacing-sizes: (
  null: $spacing-unit,
  '-tiny': $spacing-unit-tiny,
  '-small': $spacing-unit-small,
  '-large': $spacing-unit-large,
  '-huge': $spacing-unit-huge,
  '-mega': $spacing-unit-mega,
  '-giga': $spacing-unit-giga,
  '-none': 0
);

// BREAKPOINTS

$mq-breakpoints: (
  mobile:  320px,
  small:   480px,
  tablet:  740px,
  desktop: 980px,
  wide:    1300px
);
