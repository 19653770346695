.c-nav {
  margin-bottom: 0;
  margin-left: 0;  // remove default margin
  padding-left: $spacing-unit;
  padding-right: $spacing-unit;
  text-align: center;
  list-style-type: none;
  font-family: $font-family-heading;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: $font-size-7;
  text-transform: uppercase;

  &__item {
    display: inline;
  }

  &__link {
    color: #222;
    display: inline-block;
    padding: $spacing-unit-small;

    &--current {
      color: $color-text;
    }
  }
}
