.o-wrapper {
  max-width: 1280px;

  &--outer {

    padding-left: $spacing-unit-small;
    padding-right: $spacing-unit-small;

    @include mq(small) {
      padding-left: $spacing-unit;
      padding-right: $spacing-unit;
    }

    @include mq(desktop) {
      padding-left: $spacing-unit-large;
      padding-right: $spacing-unit-large;
    }
  }

  &--inner {

    padding-left: $spacing-unit;
    padding-right: $spacing-unit;

    @include mq(tablet) {
      padding-left: $spacing-unit-large;
      padding-right: $spacing-unit-large;
    }
  }
}
