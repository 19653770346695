/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  font-size: ($inuit-global-font-size / 16px) * 1em; /* [1] */
  line-height: $line-height-title;
  overflow-y: scroll; /* [2] */
  min-height: 100%; /* [3] */
  font-family: $font-family-body;
  background-color: $color-body;
  color: $color-text;

  padding-top: $spacing-unit-small;
  padding-bottom: $spacing-unit-small;

  @include mq(small) {
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
  }

  @include mq(desktop) {
    padding-top: $spacing-unit-large;
    padding-bottom: $spacing-unit-large;
  }

}
