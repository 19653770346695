// TYPOGRAPHY

$font-size-mega: 5em;
$font-size-giga: 4em;

$font-size-1: 3rem;
$font-size-2: 2.25rem;
$font-size-3: 1.5rem;
$font-size-4: 1.25rem;
$font-size-5: 1rem;
$font-size-6: .875rem;
$font-size-7: .75rem;

$measure-width: 30em;
$measure-narrow-width: 20em;
$measure-wide-width: 34em;

$line-height-solid: 1;
$line-height-default: 1.15;
$line-height-title: 1.25;
$line-height-copy: 1.5;

$font-family-body: Georgia, Times, "Times New Roman", serif;
$font-family-heading: Montserrat, sans-serif;

$font-weight-bold: 700;
$font-tracked-width: 0.05em;
