.c-band {
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;

  &--flush {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--small {
    padding-top: $spacing-unit-small;
    padding-bottom: $spacing-unit-small;
  }

  &--large {
    padding-top: $spacing-unit-large;
    padding-bottom: $spacing-unit-large;
  }

  &--huge {
    padding-top: $spacing-unit-huge;
    padding-bottom: $spacing-unit-huge ;
  }

  &--primary {
    background: #fff;
  }

  &--secondary {
    background: $color-shade;
  }
}
