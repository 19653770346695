.s-cms {

  line-height: $line-height-copy;
  max-width: $measure-wide-width;

  a {
    border-bottom: solid 1px $color-fade;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: $line-height-title;
  }

  img,
  figure {
    height: auto;
    max-width: 100% !important;
  }
}
